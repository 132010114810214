import { createApp } from 'vue'
import App from './App.vue'
import './assets/reset.css'
import router from './router'
import Loading from './components/Loading.vue'

import { setupCustomComponents } from '@/plugins/index'

const app = createApp(App)
app.component('CHLoading', Loading)
app.use(router).mount('#app')
// 注册全局自定义组件,如：<svg-icon />
setupCustomComponents(app)
