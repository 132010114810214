/* eslint-disable */
function rem() {
  (function (doc, win) {
    var docEl = doc.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        if (clientWidth >= 750) {
          docEl.style.fontSize = '100px';
        } else {
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
        }
        var html = document.getElementsByTagName('html')[0];
        const rd = 100 * (clientWidth / 750)
        let fs = parseInt(String(rd));
        var settedFs = fs
        var settingFs = fs
        var whileCount = 0;
        while (true) {
          var realFs = parseInt(window.getComputedStyle(html).fontSize);
          var delta = realFs - settedFs;
          //不相等
          if (Math.abs(delta) >= 1) {
            if (delta > 0)
              settingFs--;
            else
              settingFs++;
            html.setAttribute('style', 'font-size:' + settingFs + 'px!important');
          } else
            break;
          if (whileCount++ > 100)
            break
        }
      };
    recalc()
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
  })(document, window);
}

export { rem }



