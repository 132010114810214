import SvgIcon from './index.vue'

const importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().forEach(requireContext)
try {
  importAll(require.context('@/assets/icons', true, /\.svg$/))
} catch (error) {
  console.log(error)
}

export { SvgIcon }
