import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
const Result = () => import(/* webpackChunkName: "Result" */ '../views/Result.vue')
const Methods = () => import(/* webpackChunkName: "Methods" */ '../views/Methods.vue')
const NewHome = () => import(/* webpackChunkName: "NewHome" */ '../views/NewHome.vue')

const routes = [
  { path: '/', component: Home },
  { path: '/result', component: Result },
  { path: '/methods', component: Methods },
  { path: '/newCheckstand', component: NewHome },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
